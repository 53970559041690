import { useSubmit } from '@remix-run/react'
import type { FormEvent } from 'react'
import { useCallback } from 'react'

import useRootData from '~/hooks/useRootData'

export const useFormWithRecaptcha = (action: string) => {
  const root = useRootData()
  const submit = useSubmit()

  const submitWithRecaptca = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      const form = new FormData(event.target as HTMLFormElement)

      const token = await window.grecaptcha.execute(root?.recaptchaSiteKey, {
        action
      })

      form.append('token', token)

      submit(form, { replace: true, method: 'post' })
    },
    [action, root?.recaptchaSiteKey, submit]
  )

  return submitWithRecaptca
}

export const loadRecaptcha = (callback: () => void, recaptchaSiteKey?: string) => {
  if (document === undefined) return

  const existingScript = document.getElementById('googleMaps')
  if (!existingScript && recaptchaSiteKey) {
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`
    script.id = 'recaptcha'
    document.body.appendChild(script)
    script.onload = () => {
      if (callback) callback()
    }
  }
  if (existingScript && recaptchaSiteKey && callback) callback()
}
